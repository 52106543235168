<template>
  <div class="text-left">
    <BaseHeader :title="'Payments'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else class="pt-4">

      <div class=" mt-2 border-0 ">
        <div class=" card p-sm-2 d-flex align-items-center flex-row w-100 justify-content-between">
          <v-radio-group
            style="margin-top: 20px!important;"
            v-if="userType != userTypes.writer"
            class="group-radio my-0"
            @change="filterWriters"
            v-model="type"
            row
            align="center"
          >
            <v-radio label="All" value="all"></v-radio>
            <v-radio label="Eligible" value="active"></v-radio>
            <v-radio label="Ineligible" value="inactive"></v-radio>
          </v-radio-group>
          <h3 v-else >Payments</h3>

          <v-spacer></v-spacer>
          <!-- <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter payments"
            name="url"
            type="text"
            hide-details
          /> -->
        </div>
        <div
          class="card-body px-1 pt-1 pb-0"
          v-if="userType != userTypes.writer"
        >
          <v-alert 
          class="rounded-0"
          dense
          outlined
          prominent
          type="info" 
          >
            `Eligible` are writers whose unpaid balance is greater than
            30$
          </v-alert>


          <div class="card orderlisting--card py-2">
          <!-- <section class=" order-listing-header-main p-3  ">  
            <div class=" d-flex align-items-center justify-content-between header-elements-inline pb-0">
              
              <base-select-site
                class="p-2"
                @siteSelected="getSubjectAreas"
              ></base-select-site>
              <div class="header-elements">
                <button 
                  color="primary"
                  class="my-auto btn btn-add ml-2"
                  size="small"
                  @click.stop="crudAction"
                  >New</button
                >
              </div>
            </div>
          
          </section> -->

          <div class=" mx-0  pref-table-custom ">
          
            <div  class=" order-listing-header    row  p-3 mx-sm-0 mx-1 ">  
              <div class="col-2 text-left text--dark--custom "> ID </div>
              <div class="col-3 text-left text--dark--custom ">Name</div>
              <div class="col-2 text-left text--dark--custom">Balance</div>
              <div class="col-3 text-left text--dark--custom">Eligible for Payout</div>
              <!-- <div class="col-2 text-left text--dark--custom"> Status</div> -->
              <div class="col-2 text-left text--dark--custom">Action</div>
          </div>
        

          <div v-if="writer_list.length > 0" class="">
          <div v-for="(item, index) in writer_list" :key="index + 'ords'" class="order-listing-body row px-3 py-3 mx-sm-0 mx-1 ">  
              <div class="col-2 d-flex align-items-start justify-content-start text-left">	
                <span style="font-weight:500!important" class="px-2"
                  >{{ item.id || "" }}</span>
                
              </div>
              <div class="col-3 text-left">
                <div style="font-weight:500!important" class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.names || "" }}</div>
              </div>
              <div class="col-2 text-left">${{ item.balance || "" }}</div>
              <div style="font-weight:500!important" class="col-3 text-left">  
                <v-chip outlined :color="item.status ? 'primary' : 'error'" small>
                  {{ item.status ? "Eligible" : "Ineligible" }}
                </v-chip>
                
              </div>
              <!-- <div style="font-weight:500!important" class="col-2 text-left">  
                <v-chip :color="item.status ? 'primary' : 'error'" small outlined>{{
                  item.status ? "Active" : "Inactive"
                }}</v-chip>
                
              </div> -->
              
              <div class="col-2 text-left  ">
                <v-btn
                :to="{ name: 'ViewWriterPending', params: { id: item.id } }"
                fab
                elevation="2"
                x-small
                icon
              >
              <v-icon scale="1.3" name="eye" color="black" />

              </v-btn>

              
              </div>
          </div>
          </div>

          </div>
          <div class="p-3 d-flex align-items-center justify-content-center w-100 "  v-if="writer_list.length < 1">
            
                <strong class="order_empty  text-center" >No Payments</strong>
            
          </div>
        </div>




        </div>

        <div v-else class="card px-1 pt-1 pb-0 py-4">
          <div class="m-2">
            To view your pending payments and paid, click
            <router-link
              :to="{ name: 'ViewWriterPending', params: { id: user.id } }"
              >here.</router-link
            >
            <br />
            <router-link
              :to="{ name: 'ViewWriterPending', params: { id: user.id } }"
              class="btn btn-add  mt-2"
              >View my payments</router-link
            >
          </div>
        </div>
      </div>

      <div
        class="card border-0 bg-white shadow"
        v-if="selected.length > 0 && userType == userTypes.admin"
      >
        <div class="card-body">
          <h5 class="text-uppercase">With Selected</h5>
          <button @click="markAsPaid" class="btn btn-primary">
            Mark as Paid
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
// import http from "../../modules/http.index";
export default {
  name: "Payments",
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("payment", [
      "pendingTotals",
      "pendingPayments",
      "pendingPaymentWriters",
      "pendingPaymentsHeaders",
    ]),
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("writers", ["writers", "loading"]),
  },

  data() {
    return {
      writer_id: "",
      selected: [],
      writer_list: [],
      type: "all",
      loading: true
    };
  },

  methods: {
    ...mapActions("writers", ["getActiveWriters"]),
    filterWriters() {
      if (this.type === "active") {
        this.writer_list = this.writers.filter((item) => {
          return Boolean(item.status) === true || item.status === "1";
        });
      } else if (this.type === "inactive") {
        this.writer_list = this.writers.filter(
          (item) => Boolean(item.status) === false
        );
      } else {
        this.writer_list = this.writers;
      }
    },
  },

  async mounted() {
    if (this.userType != this.userTypes.admin) {
      console.log("user", this.user);
    }
    this.loading = true
    try {
      await this.getActiveWriters();
      this.writer_list = this.writers;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false
    }
  },
};
</script>
